import React from 'react';

import MenuItem from './MenuItem';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import {Aponame} from './Apodaten';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faPhone, faFax, faEnvelope, faUser} from '@fortawesome/free-solid-svg-icons'

import Kontaktdaten from './Kontaktdaten';


const Kontakt = (props) => {
    return (
        <div className="contact">
            <Container>                
                <Card
                    style={
                        {
                            backgroundColor: props.bg,
                            border: "none",
                            margin: "10px 0"
                        }
                    }
                    className=""
                    >
                    <Card.Body>
                        <Container>
                        <h3><Aponame /></h3>
                        <Kontaktdaten />
                            
                            <Row style={{marginTop: "20px"}}>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6255.400192949941!2d11.336814749738753!3d48.761425162174355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479efc4c7282db69%3A0x28b59f927df6df13!2sVital%20Apotheke!5e0!3m2!1sde!2sde!4v1692617198478!5m2!1sde!2sde" width="100%" height="300" style={{border: 0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </Row>
                        </Container>
                    </Card.Body>
                </Card>
            </Container>
            <Container>
                
            </Container>
        </div>        
    )
}

export default Kontakt;