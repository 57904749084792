import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

import MainButton from './components/MainButton';
import FooterMenu from './components/FooterMenu';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from './components/Header';
import OpeningHours from './components/OpeningHours';
import PushNews from './components/pushNews';
import Kontakt from './components/Kontakt';
import Empfehlung from './components/Empfehlung';
import Datenschutz from './pages/Datenschutz';
import Home from './components/Home';


function App() {
  
  return (

<BrowserRouter>
        <div>
            <Routes>
             <Route path="/datenschutz" component={Datenschutz} exact/>
            <Route component={Error}/>
           </Routes>
        </div> 
        <div className="App">
<Container>
  <Row>
    <Header />
  </Row>
  {/*  Empfehlung */}
 {/* <Empfehlung />*/}
  <Row>  
    <Col xs="6">
    <MainButton link="https://www.vitalapo-ingolstadt.de/vorbestellung/#bestellung" title="Rezept" bg="#ea464f" dynClass="icon-red" image={3}/>
    </Col> 
    <Col xs="6">
      <MainButton link="https://www.vitalapo-ingolstadt.de/vorbestellung/#bestellung" title="E-Rezept" bg="#1f8f3a" dynClass="icon" image={0}/>
    </Col>       
  </Row>
  <Row>
    <Col xs="6">
      <MainButton link="https://www.vitalapo-ingolstadt.de/kontakt/#kontakt" title="Termine" bg="#1f8f3a" dynClass="icon" image={6}/>
    </Col>      
    <Col xs="6">
      <MainButton link="https://www.vitalapo-ingolstadt.de/angebote/#angebote" title="Angebote" bg="#1f8f3a" dynClass="icon" image={4}/>
    </Col>   
  </Row>
</Container>     
{/* <PushNews /> */}
<OpeningHours />
<Kontakt />
<div>
<a href="https://www.vitalapo-ingolstadt.de/impressum/">Impressum</a>
  <a  className="Footer-menu" href="https://www.vitalapo-ingolstadt.de/datenschutz/">Datenschutz</a>
</div>
<footer>
  <FooterMenu />
</footer>
</div>
</BrowserRouter>
   
  );
}

export default App;
