import React from 'react';
import { BrowserRouter, Route, Link } from "react-router-dom";


import MenuItem from './MenuItem';
import Datenschutz from './../pages/Datenschutz';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faHouse } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom';


const FooterMenu = (props) => {
    //const path = useLocation().pathname
    return (
        <Container className="footermenu">
            <Row>
                <Col><MenuItem title="Start" icon={0} link="" /></Col>
                <Col><MenuItem title="Beratung" icon={3} link="https://www.vitalapo-ingolstadt.de/beratung/" /></Col>
                {/*<NavLink to="home">
                    <Col><MenuItem title="Einnahmeplan" icon={5} link="" /></Col>
                </NavLink>*/}
                {/*<Col><MenuItem title="Beratung" icon={3}/></Col>*/}
                <Col><MenuItem title="Kontakt" icon={4} link="https://www.vitalapo-ingolstadt.de/kontakt/"/></Col>
            </Row>
        </Container>
    )
}


export default FooterMenu;