import React from 'react';

import MenuItem from './MenuItem';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faHouse } from '@fortawesome/free-solid-svg-icons'
import SearchBar from './SearchBar';
import Kontaktdaten from './Kontaktdaten';

const Header = (props) => {
    return (
        <React.Fragment>
            <div className="App-header">
                <Container>
                    <Row>
                        <Col className="logozeile" ><img src="img/vital-apotheke.png" /></Col>
                    </Row> 
                    <Row>   
                        <Col className="kontaktzeile"><Kontaktdaten /></Col>
                    </Row>
                </Container>
            
               {/* <SearchBar /> */}
            </div>            
            
        </React.Fragment>
        
    )
}

export default Header;