
const Aponame = (props) => {
    return (
    'Vital-Apotheke'
        )
}

const Strasse = (props) => {
    return (
    'Barthlgasserstr. 33'
        )
}

const PLZ = (props) => {
    return (
    '85049'
        )
}

const Ort = (props) => {
    return (
    'Ingolstadt'
        )
}

const Telefonnummer = (props) => {
    return (
    '+49 (0) 841-9939221'
        )
}

const Email = (props) => {
    return (
    'info@vitalapo-ingolstadt.de'
        )
}

const Montag = (props) => {
    return (
    '08:00 - 20:00 Uhr'
        )
}

const Dienstag = (props) => {
    return (
    '08:00 - 20:00 Uhr'
        )
}

const Mittwoch = (props) => {
    return (
    '08:00 - 20:00 Uhr'
        )
}

const Donnerstag = (props) => {
    return (
    '08:00 - 20:00 Uhr'
        )
}

const Freitag = (props) => {
    return (
    '08:00 - 20:00 Uhr'
        )
}

const Samstag = (props) => {
    return (
    '08:00 - 14:00 Uhr'
        )
}



const GoogleMaps = (props) => {
    return (
        ''
       )
}

export {Aponame, Strasse, PLZ, Ort, Telefonnummer, Email, GoogleMaps}





